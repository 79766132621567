/* About.css */

/* Container Styles */
.about {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Heading Styles */
h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333; /* Text color */
}

/* Text Styles */
p {
  font-size: 16px;
  line-height: 1.5;
  color: #666; /* Text color */
}
