/* CSS for the header */
.header {
  background-color: #333; /* Set the background color as needed */
  color: #fff; /* Set the text color as needed */
  padding: 16px; /* Add padding as needed */
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: left-side;
  align-items: center;
}

nav li {
  margin: 0 10px; /* Adjust the margin as needed to create space between links */
}

nav a {
  text-decoration: none;
  color: #fff; /* Set the link text color as needed */
  font-weight: bold; /* Add font styling as needed */
}

/* Style for the website link */
.website-link {
  /* Add styling for the link to your website */
}
