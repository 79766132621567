/* CSS for the footer */
.footer {
  background-color: #333; /* Set the background color as needed */
  color: #fff; /* Set the text color as needed */
  padding: 16px; /* Add padding as needed */
  text-align: center;
}

.footer p {
  margin: 8px 0; /* Adjust the margin as needed */
}

/* Style for links within the footer */
.footer a {
  text-decoration: none;
  color: #fff; /* Set the link text color as needed */
}

/* Style for links on hover */
.footer a:hover {
  text-decoration: underline; /* Add an underline effect on hover */
}
