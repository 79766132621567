/* CSS for the gallery */
.gallery {
  text-align: center;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust the column width as needed */
  gap: 16px; /* Adjust the gap between images as needed */
}

.gallery-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
