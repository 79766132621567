/* CSS for the generator */
.generator {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7; /* Set the background color as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.generator h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.generator .heading {
  font-size: 18px;
  margin: 20px 0;
}

.generator .text {
  font-size: 16px;
  margin-bottom: 20px;
}

.generator .input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.generator input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.generator .button {
  background-color: #007bff; /* Set the button background color as needed */
  color: #fff; /* Set the button text color as needed */
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
}

.generator .button:hover {
  background-color: #0056b3; /* Set the button hover color as needed */
}


